<template>
  <div>
  
  <b-card v-if="!round" title="Nenhuma rodada em andamento">
    <b-card-text>Você pode iniciar uma rodada!</b-card-text>
    <router-link :to="{ name: 'next-rounds'}"  class="text-white">
      <b-button
        type="submit"
        variant="success"
        class="mr-1"
        Submit>
        <feather-icon
          icon="PlayIcon"
          class="mr-50"
        />
        <span>Iniciar uma nova rodada</span>
      </b-button>
    </router-link>
    <b-button
      type="submit"
      variant="primary"
      class="mr-1"
      Submit>
      Ver rodadas anteriores
    </b-button>
  </b-card>
  <div v-else class="row">
    <b-card class="col-12 col-lg-6" v-bind:title="'Dados da rodada - ' + round.id">
      <div class="row">
        <div class="col-12 col-lg-6">
          <b-list-group>
            <b-list-group-item class="d-flex">
              <span class="mr-1">
                <feather-icon
                  icon="UserCheckIcon"
                  size="16"
                />
              </span> 
              <span>Jogadores: </span><strong>{{round.total_users_in}}</strong>
            </b-list-group-item>
            <b-list-group-item class="d-flex">
              <span class="mr-1">
                <feather-icon
                  icon="GridIcon"
                  size="16"
                />
              </span>
              <span>Cartelas em jogo: </span><strong>{{round.total_cards_in}}</strong>
            </b-list-group-item>
            <b-list-group-item class="d-flex">
              <span class="mr-1">
                <feather-icon
                  icon="DollarSignIcon"
                  size="16"
                />
              </span>
              <span>Valor da cartela: </span><strong>{{round.card_price | toCurrency}}</strong>
            </b-list-group-item>
            <b-list-group-item class="d-flex">
              <span class="mr-1">
                <feather-icon
                  icon="CircleIcon"
                  size="16"
                />
              </span>
              <span>Golden Ball:</span>
              <strong class="flex-align-end">{{round.golden_ball}}</strong>
            </b-list-group-item>
          </b-list-group>

        </div>
        <div class="col-12- col-lg-6">
          <b-list-group>
            <b-list-group-item class="d-flex">
              <span class="mr-1">
                <feather-icon
                  icon="DollarSignIcon"
                  size="16"
                />
              </span>
              <span>Acumulado: </span><strong>{{jackpot | toCurrency}}</strong>
            </b-list-group-item>
            <b-list-group-item class="d-flex">
              <span class="mr-1">
                <feather-icon
                  icon="DollarSignIcon"
                  size="16"
                />
              </span>
              <span>LINHA: </span><strong>{{(round.final_total_prize * round.first_prize_percentage)/100 | toCurrency}}</strong>
            </b-list-group-item>
            <b-list-group-item class="d-flex">
              <span class="mr-1">
                <feather-icon
                  icon="DollarSignIcon"
                  size="16"
                />
              </span>
              <span>LINHA DUPLA: </span><strong>{{(round.final_total_prize * round.second_prize_percentage)/100 | toCurrency}}</strong>
            </b-list-group-item>
            <b-list-group-item class="d-flex">
              <span class="mr-1">
                <feather-icon
                  icon="DollarSignIcon"
                  size="16"
                />
              </span>
              <span>BINGO: </span><strong>{{(round.final_total_prize * round.third_prize_percentage)/100 | toCurrency}}</strong>
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>

<b-card class="col-12 col-lg-6" style="padding:0" title="Funciones Ronda">
          
            <div>


<div class="row"><b-button
              type="button"
              variant="primary"
              class="mr-1 m-control"
              @click="freecards()"
              >
              <span>LIBERAR Cartones</span>
            </b-button>
  </div>
      <div class="row"><b-button
              type="button"
              variant="danger"
              class="mr-1 m-control"
              @click="cancel()"
              >
              <span>CANCELAR Jogo</span>
            </b-button>
          </div>
  </div>
        </b-card>        
      <b-card class="col-12 col-lg-6" style="padding:0" title="Controle da Máquina">
          <div>
            <b-form-input
              id="machine-ip"
              v-model="machineIp"
            />
          </div>
          <div class="d-flex">
            <b-button
              type="button"
              variant="primary"
              class="mr-1 m-control"
              @click="machineAuth()"
              >
              <span>Autenticar</span>
            </b-button>
            <b-button
              type="button"
              variant="primary"
              class="mr-1 m-control"
              @click="machineInit()"
              >
              <span>Inicializar</span>
            </b-button>
            <b-button
              type="button"
              variant="primary"
              class="mr-1 m-control"
              @click="blowerOn()"
            >
              <span>Turbina ON</span>
            </b-button>
          </div>
          <div class="d-flex w-100">
            <b-button
              type="button"
              variant="success"
              class="mr-1 m-control"
              @click="ballCall()"
              >
              <span>Passar a bola</span>
            </b-button>
            <b-button
              type="button"
              variant="danger"
              class="mr-1 m-control"
              @click="blowerOff()"
              
              >
              <span>Turbina OFF</span>
            </b-button>
            <b-button
              type="button"
              variant="danger"
              class="mr-1 m-control"
              @click="MDXreset()"
              >
              <span>Finalizar Jogo</span>
            </b-button>
          </div>
            <div>
  </div>
        </b-card>      
      <b-card v-if="round.status == 2" title="Computar bola" class="mt-2">
        <b-button
              type="button"
              variant="danger"
              class="mr-1 m-control"
              @click="autopickball()"
              v-if="showAuto"
              >
              <span>AUTO</span>
            </b-button>
        <b-row>
          <ul style="padding:0">
            <li v-bind:id="'number_'+i" class="select-num" v-for="i in 90" @click="clickNumber(i)">{{i}}</li>
          </ul>
        </b-row>
        <b-form @submit.prevent>
        <b-row class="d-none">
            <b-col>
              <b-form-input
                id="ball-to-call"
                :formatter="ballFormatter"
                v-model="ballNumber"
                lazy-formatter
                pattern="\d*"
              />
            </b-col>
            <b-col class="d-flex">
              <b-button 
                id="send-ball-bt"
                md="6"
                type="submit"
                variant="danger"
                class="mr-1"
                Submit
                v-b-modal.confirm-submit
                >
                Enviar nova bola
              </b-button>   
            </b-col>
          </b-row>
        </b-form>
      </b-card>
      <b-card v-else class="mt-3" title="Rodada prestes a iniciar">
        <div id="clock">
          <span class="time">{{time.hours | timer}}:{{time.minutes | timer}}:{{time.seconds | timer}}</span>
        </div>
      </b-card>
    </b-card>
    <b-card id="number-box" class="col-12 col-lg-6" title="Numeros sorteados">
      <ul id="picked-numbers">
        <li  class="picked-number" v-for="n in round.picked_balls">{{n.number}}</li>
      </ul>
      <b-card title="Ganhadores">
      <div v-if="round.winners" class="d-flex">
        <div v-for="winner in round.winners" class="winner col">
          <h4 class="text-success">{{winner.prize.name}}</h4>
          <h4>{{winner.user.name}}</h4>
          <h4>{{winner.prize_collected | toCurrency}}</h4>
        </div>
      </div>
    </b-card>
    </b-card>
  </div>
  <div class="row" v-if="round.cardsToGo">
    <b-card class="col-6" title="Faltando 1">
      <p v-for="card in round.cardsToGo.TG_4" class="card">
        {{card.fullId}} - {{card.userName}} - Linha: {{card.line}} - Numero: {{card.numbers}}
      </p>
    </b-card>
    <b-card class="col-6" title="Faltando 2">
      <p v-for="card in round.cardsToGo.TG_3" class="card">
        {{card.fullId}} - {{card.userName}} - Linha: {{card.line}} - Numeros: {{card.numbers}}
      </p>
    </b-card>
  </div>

  <b-modal v-if="ballNumber > 0"
    id="confirm-submit" 
    title="Você tem certeza?!"
    @ok="sendBall"  
  >
    <p class="my-4 text-center">Você está enviando a bola de número:</p>
    <p class="ball-confirm text-success">{{ballNumber}}</p>
  </b-modal>

  </div>
</template>

<script>
import { IAuthTokens, TokenRefreshRequest, applyAuthTokenInterceptor } from 'axios-jwt'
import { BCard,BRow,BForm, BCol, BFormGroup, BFormInput, BCardText, BButton, BListGroup, BListGroupItem } from 'bootstrap-vue'
import moment from 'moment';
import axios from 'axios';

export default {
  components: {
    BCard,
    BForm,
    BCardText,
    BButton,
    BListGroup, 
    BListGroupItem,
    BRow, BCol, BFormGroup, BFormInput,
    moment,
    axios
  },
  data(){
    return{
      ballNumber : "",
      round:false,
      jackpot: 0,
      closestCards: false,
      time:{
        'hours': 0,
        'minutes': 0,
        'seconds': 0
      },
      interval : false,
      mdxJWT : "",
      machineIp: "",
      maxineAxios: null,
      numbers : [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,
      ,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79
      ,80,81,82,83,84,85,86,87,88,89,90],
      showAuto: false
    }
  },
  mounted(){

    this.machineAxios = this.$http;
    this.machineAxios.defaults.headers.post['Content-Type'] ='application/json';
    this.getCurrentRound().then(()=>{
      if(this.round.status == 6){
        const self = this;
        const startDate = moment(this.round.start_date);
        this.interval = setInterval(function() {
            const nowDate = moment();
            let differ = startDate.diff(nowDate);
            self.time.hours = Math.floor((differ % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            self.time.minutes = Math.floor((differ % (1000 * 60 * 60)) / (1000 * 60));;
            self.time.seconds = Math.floor((differ % (1000 * 60)) / 1000);
            if(differ <= 0)
              window.location.reload()
        },1000);
      }

      this.checkBalls();

      this.showAuto = this.$route.query.test == 'yes';

      console.log(this.$route.query.test);
    })
  },
  methods:{
    shuffleArr(array){
        for (var i = array.length - 1; i > 0; i--) {
            var rand = Math.floor(Math.random() * (i + 1));
            [array[i], array[rand]] = [array[rand], array[i]]
        }
    },
    delay(delayInms) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(2);
        }, delayInms);
      });
    },

    cancel(){
      try{
        this.$http.post('/game/cancel?roundId='+this.round.id).then(res => {
          alert("Jogo Cancelado")
          window.location.reload();

          console.log(res)
        })
      }catch(e){
        console.log(e)
      }
    },

    freecards(){
                console.log("freecards")
      try{
        this.$http.post('/cards/free?roundId='+this.round.id).then(res => {
          alert("Cartones liberados")


          console.log(res)
        })
      }catch(e){
        console.log(e)
      }
    },    
    async autopickball(){
      console.log("aca",this.numbers);

      this.shuffleArr(this.numbers);

      while(this.numbers.length>0){
        let num = this.numbers[0];

        console.log(num);
        this.ballNumber = num

        this.sendBall();
        let delayres = await this.delay(1800);
      }

    },
    ballFormatter(value) {
      if(value > 0 && value < 91){
        if(value < 10)
          return value *1;
        return value;
      }else{
        value = ""
        return value
      }
    },
    sendBall(){
      this.$http
      .post('/game/add-game-ball', {
        ballNumber: this.ballNumber,
        roundId: this.round.id,
      })
      .then(res => {
        console.log(res.data);
        if(res.data.roundStatus == 3){
          window.location.href = '/round-summary/' + res.data.roundId;

        }else{
          this.getCurrentRound();
        }
      }).catch(function(e) {
        alert(e.response.data);
        console.log(e);
      })
    },
    checkBalls(){

      let picked = []
      for(var i in this.round.picked_balls){
        const num = this.round.picked_balls[i].number;
        document.getElementById("number_"+num).classList.add("num-selected");
      }
    },
    async getCurrentRound(){
      try {
        await this.$http.get('/room/current-round')
        .then(res => {
          this.round = res.data;

          let picked = []
          for(var i in res.data.picked_balls){
            const num = res.data.picked_balls[i].number;
            document.getElementById("number_"+num).classList.add("num-selected");
            picked.push(num);
          }
            
          this.numbers = this.numbers.filter((n)=> n!=null && n!=undefined &&  picked.indexOf(n) ==-1 );

          console.log("getCurrentRound", this.numbers);
          this.getJackpotPrize();

          setTimeout(()=>{this.checkBalls()},1000);
        })
      }catch(e){
        console.log(e)
      }
    },
    getJackpotPrize(){
      this.$http.get('/room/jackpot-prize')
     .then(res => {
       this.jackpot = res.data
      })
    },
machineAuth(){
      try{
        this.machineAxios.post('/bolillero/login', {
          "username" : "admin",
          "password" : "Admin08"
        }).then(res => {
          alert("Login Efetuado")
          this.mdxJWT = res.data.token;
          // save tokens to storage
          console.log(this.machineAxios);
          this.machineAxios.defaults.headers.common['access-token-proxy'] =res.data.token;
          //this.machineAxios.defaults.headers.post['access-token-proxy'] =res.data.token;

          /*setAuthTokens({
            accessToken: this.mdxJWT
          })*/
        })
      }catch(e){
        console.log(e)
      }
    },
    machineInit(){
      try{
        this.machineAxios.get('/bolillero/init').then(res => {
          console.log(res)
          alert("Inicialização Efetuada")
        })
      }catch(e){
        console.log(e)
      }
    },
    blowerOn(){
      try{
        this.machineAxios.post('/bolillero/turbinaon').then(res => {
          console.log(res)
          alert("Turbina Ligada")
        })
      }catch(e){
        console.log(e)
      }
    },
    ballCall(){
      try{
        this.machineAxios.post('/bolillero/pasebola',{
          "readball" : false,
          "sysid" : "A01", 
          "message" : ""
        }).then(res => {
          console.log(res)
        })
      }catch(e){
        console.log(e)
      }
    },
    blowerOff(){
      try{
        this.machineAxios.post('/bolillero/turbinaoff').then(res => {
          alert("Turbina Desligada")
          console.log(res)
        })
      }catch(e){
        console.log(e)
      }
    },
    MDXreset(){
      try{
        this.machineAxios.post('/bolillero/reset').then(res => {
          alert("Reset executado")
          console.log(res)
        })
      }catch(e){
        console.log(e)
      }
    },
    clickNumber(num){
        this.ballNumber = num
        document.getElementById("send-ball-bt").click();
    }
  },
  filters: {
    timer: function (value) {
      if(value.toString().length < 2){
        return "0"+ value;
      }else{
        return value;
      }
    }
  }
}
</script>

<style>
.list-group-item{
  background-color:transparent;
  border:1px solid rgba(255,255,255,0.3);
  justify-content: space-between;
}

#number-box{
  min-height: 640px;
}
#picked-numbers{
    list-style: none;
}
.picked-number{
    font-size: 18px;
    font-weight: bold;
    padding: 10px 10px 10px 12px;
    margin: 5px;
    border-radius: 50px;
    border: 1px solid;
    text-align: center;
    width: 50px;
    height: 50px;
    line-height: 26px;
    text-align: center;
    display: inline-block;
}
.picked-number:last-child{
  color:yellow;
}

#ball-to-call{
  height: 60px;
    font-size: 45px;
    line-height: 60px;
    color: #fff;
    text-align: center;
    border: 1px solid;
    font-weight: bold;
}
.ball-confirm{
  font-size: 70px;
  font-weight: bold;
  text-align: center;
}
#machine-ip{
  display: block;
    border: 1px solid;
    width: 250px;
    margin: 10px 0;
    font-size: 18px;
    font-weight: bold;
}
.select-num{
  font-size: 18px;
  display: inline-block;
  padding: 5px;
  margin: 5px;
  border: 1px solid;
  width: 51px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
.m-control{
  min-width: 149px;
  display: block;
  margin-top: 15px!important;
}
.num-selected{
  background-color: red;
}
</style>
